import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HighScore from '../views/HighScore.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'EventList',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventList.vue')
  },
  {
    path: '/event/:eventName',
    name: 'Event',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/EventView.vue')
  },
  {
    path: '/highscore/:eventName',
    name: 'HighScore',
    props: true,
    component: HighScore
  },
  {
    path: '/highscore/setup',
    name: 'HighScoreSetUp',
    props: true,
    component: () => import('../views/highscore/SetUp.vue')
  },
  {
    path: '/insert4hs',
    name: 'Insert4hs',
    props: true,
    component: () => import('../views/Insert4Hs.vue')
  },
    {
    path: '/final/consilio',
    name: 'Consilio',
    component: () => import('../views/finals/ConSilio.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
