<template>
  <div id="top10Container" class="highscore">

      <div  id="highscoreEndContainer" class="highscoreContainer"  v-if="showHighscore === true">
    <h1 class="eventHeadline"> Top 10 </h1>
				<div class="hs-row" v-for="(item, index) in highscoresLimit[eventName]" :key="index">
					<div class="hs-index" >Platz {{index + rankoffset }}</div> 
						<div class="hs-playerName" >{{item.playerVorName}} {{item.playerName}} </div> 

						<div class="hs-playerPoints" v-if="settings.eventScoreType == 0" >{{Number(Math.floor(item.points / 60)).pad(2)}}:{{ Number(item.points % 60).pad(2)}} Watt</div> 
            <div class="hs-playerPoints" v-if="settings.eventScoreType > 0" >{{ (item.points / 1000).toFixed(2) }} &nbsp; Wattsekunden</div> 
            
				</div>
			</div>
 
  </div>
</template>



<script>
  import {mapGetters} from "vuex";
   import { mapActions } from "vuex";

export default {
  name: 'TopTen',
  props: ["eventName", "settings"],
  components: {
  },

   data () {
    return {
      rankoffset: 1,
      myList: [],
      defaultEventSetting: {
        active:false,
        eventScoreType: 1,
        hasHighScore: true,
        iTime: 0,
        name: "allianz",
      }
    }
  },

  computed:{
    ...mapGetters([
      'highscores',
      'highscoresLimit',
      'showHighscore',
    ]),
  },

   created() {
     this.getHighScoreListByEvent(this.eventName)
     if (!this.settings) {
      this.settings = this.defaultEventSetting
     }
  },

  mounted() {
   // this.myList = this.highscoresLimit[this.eventName];
  },

  methods: {

    ...mapActions([
      'getHighScoreListByEvent',
    ]),


  },

}

</script>

<style lang="scss" >


.top10 {
	position: absolute;
	text-align: center;
    top: 18vh;
    left: 10vw;
    font-size: 5vh;
    line-height: 3.5vh;
    width: 80vw;
    font-family: 'helveticaLR', Arial, sans-serif;
}

.highscoreContainer {
    position: relative;
    font-size: 2vh;
   //  line-height: 3.5vh;
    width: 80vw;
    font-family: 'helveticaLR', Arial, sans-serif;
    color: white;
    white-space: nowrap;
    
}
.hs-row {
    width: 100%;
    display: flex;
    padding: 0.6vw 1.4rem;
  //  font-size: 5vw;
    color: black;
}
.hs-highlight {
    background-color: #eaff00;
}

.hs-playerName {
    min-width:  25vw;
    width:  50vw;
    max-height: 4.5vh;
    text-overflow: ellipsis;
}
.hs-playerVorName {
    width:  27vw;
    min-width:  20vw;
    max-height: 4.5vh;
    text-overflow: ellipsis;
}
.hs-playerPoints {
    width: 10vw;
    text-align: right;
}
.hs-index {
    width:  10vw;
}


</style>
