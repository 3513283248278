<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Power</router-link> |
      <router-link to="/about">Tacho</router-link>

       <router-link v-if="authenticated" to="/login" v-on:click.native="logout()" replace>Logout</router-link>

       
    </div> -->

    <div id="nav">
       
    </div>
    <router-view @authenticated="setAuthenticated" />
  </div>
</template>

<script >
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: "BubTable",
  components: {
  },
  data() { return {
      interval: null,

      authenticated: true,
      mockAccount: {
          username: "BUB",
          password: "Berlin"
      }
    }
  },


  created () {
    this.initSensors()
    this.getApiVersions()
  },
  
  mounted() {
            if(!this.authenticated) {
                this.$router.replace({ name: "login" });
            }
        },
  methods: {
    ...mapActions([
      'initSensors',
      'getApiVersions',
    ]),

    setAuthenticated(status) {
        this.authenticated = status;
    },
    logout() {
        this.authenticated = false;
    }
  }

}
</script>

<style>

  body, html {
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-color: #333333;
    font-family: Allianz Neo, Frutiger, Hevetica, Arial, sans-serif;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-Bold.ttf')
    format("truetype");
    font-weight: bolder;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-BoldItalic.ttf')
    format("truetype");
    font-weight: bolder;
    font-style: italic;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-Condensed.ttf')
    format("truetype");
    font-stretch: condensed;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-CondensedBold.ttf')
    format("truetype");
    font-stretch: condensed;
    font-weight: bolder;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-CondensedLight.ttf')
    format("truetype");
    font-stretch: condensed;
    font-weight: lighter;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-Italic.ttf')
    format("truetype");
    font-style: italic;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-Light.ttf')
    format("truetype");
    font-weight: 1;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-LightItalic.ttf')
    format("truetype");
    font-weight: lighter;
    font-style: italic;
  }
  
  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-Regular.ttf')
    format("truetype");
    font-weight: 400;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-SemiBold.ttf')
    format("truetype");
    font-weight: bold;
  }

  @font-face {
    font-family: Allianz Neo;
    src: url('/fonts/Allianz/AllianzNeo-SemiBoldItalic.ttf')
    format("truetype");
    font-weight: bold;
    font-style: italic;
  }


  @font-face {
    font-family: Frutiger;
    src: url('/fonts/frutiger/FrutigerLTCom-LightCn.ttf')
    format("truetype");
  }


  .text {
    font-family: Frutiger, Hevetica, Arial, sans-serif;
    /* cursor: none; */
    font-size: 3vh;
    font-weight: 100;
    text-align: right;
    margin: 1vh 0;
  }

  button {
    cursor: pointer;
    padding: 1.5vh;
    margin: 0.5vh;
    border-color: rgb(0, 255, 0);
    border-width: 0.1vw;
    background-color: black;
    color: rgb(0, 255, 0);
    width: auto;
    display: inline-block;
  }

  .device {
    margin: 1vw;
    /* background: #ddd; */
  }

  .devices {
    overflow: scroll;
  }

    .deviceImg {
    width: 10vw;
    margin: 0 1vw;
  }
  .twoRows {
    display: flex;
  }
  .ttRow {
    /* margin: 1vw; */
    padding: 0 1vw;
    max-width: 50%;
    min-width: 20%;
  }

      
</style>
