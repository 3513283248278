import axios from 'axios';

Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

const state = {
  VERSION: 0.4,
  axiosHeader: { headers: {} },
  HsApiUrl: 'http://localhost:8110/highscoreapi/',

  //saveDataUrl_Elias: 'http://tentable.de:8100/highscoreapi/uploadhighscore',
  // saveDataUrl_Elias: 'http://localhost:8100/highscoreapi/uploadhighscore',
//  saveDataUrl: 'http://localhost:8100/highscoreapi/savedata',

windparkSaveHSPath: "highscoreapi/event/allianz/addscore",
windparkSaveHSPathTOTAL: 'http://tentable.de:8100/highscoreapi/event/allianz/addscore',

  highscores: {},
  highscoresLimit: {},

  showHighscore: false,
  eventList: [],
  namedEventList: [],

};
// ============================ END STATE ===========================

// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  VERSION: state => {return state.VERSIONS},
  highscores: state => {return state.highscores},
  highscoresLimit: state => {return state.highscoresLimit},
  showHighscore: state => {return state.showHighscore},
  baseUrlHs: ( state, rootState) => {return  rootState.baseApiUrl + state.HsApiUrl},
  eventList: state => {return state.eventList},
  namedEvent: state =>  (_name) => {return state.namedEventList[_name]},
};

// ================== ACTIONS =========================
const actions = {

  
  async saveHSWindPark({dispatch, state, rootState}, _dataObj) {
    console.log('X -> saveHSWindPark ->  ', _dataObj)

   // const url = rootState.baseApiUrl + state.windparkSaveHSPath
   const url = state.windparkSaveHSPathTOTAL
   console.log('X -> saveHSWindPark ->  url' + url, _dataObj)
    let response = await axios.post(url, _dataObj, state.axiosHeader)
    console.log('X -> saveHSWindPark ->  response', response)
    // response.data.eventName = _dataObj.eventName
  //  let instance = Vue.$toast.open("OK");
    // dispatch("setHSList", response.data)
  },

   async sendNewScoreToServer({dispatch, state, rootState}, _dataObj) {
      console.log('X -> sendHSDataToServer ->  ', _dataObj)

      const url = state.HsApiUrl + "addDataScore"
      let response = await axios.post(url, _dataObj, state.axiosHeader)
      console.log('X -> sendDataToServer ->  response', response)
      response.data.eventName = _dataObj.eventName
 //     let instance = Vue.$toast.open("OK");
      dispatch("setHSList", response.data)
    },

        async newEventByName ({dispatch, state}, _eventName) {
            if ( _eventName == "") {
              console.log('X -> newEventByName  -> FAIL - NO EVENTNAME ' + _eventName)
              return ; 
            }
            let _dataObj = {"name": _eventName}

            const url =  state.HsApiUrl + 'newEventByName'
console.log('X -> newEventByName -> url: ' + url)
            let response = await axios.post(url, _dataObj, state.axiosHeader)
            dispatch("getEvents")
        },


        async deleteEvent ({dispatch, state}, _event) {
          console.log('X -> deleteEvent ->  ', _event)
          const url = state.HsApiUrl + 'deleteevent'
          let response = await axios.post(url, _event, state.axiosHeader) 
          dispatch("getEvents")
        },


         async resetHighScores ({dispatch, state, rootState}, _event) {
          console.log('X -> deleteHighScores ->  ', _event.name)
          const url = state.HsApiUrl + "event/resetscore"
          let response = await axios.post(url, _event, state.axiosHeader) 
         // response.data.eventName = _eventName
          //dispatch("setHSList", response.data)
          // dispatch("setShowHighscore", true)
          // TODO - Reload ... 
        },

        async newHighScores ({dispatch, state, rootState}, _eventName) {
          console.log('X -> newHighScores ->  ', _eventName)
          const url = rootState.baseApiUrl + state.HsApiUrl + 'event/' + _eventName + "/newscore"
          let response = await axios.get(url, state.axiosHeader) 
          console.log(response)
         // response.data.eventName = _eventName
          //dispatch("setHSList", response.data)
          // dispatch("setShowHighscore", true)
          // TODO - Reload ... 
        },

       async getEvents ({dispatch, state}) {
          console.log('X -> getEvents ->  ')
          const url = state.HsApiUrl + 'eventList'
          let response = await axios.get(url, state.axiosHeader) 
          dispatch("setEventList", response.data.eventList)
       },

       async getEventByName ({commit, state}, _name) {
        console.log('X -> getEventByName ->  ' + _name)
        const url = state.HsApiUrl + 'event/' + _name
        let response = await axios.get(url, state.axiosHeader) 
        commit("setEvent", response.data.event)
     },

     async deleteScoreEntry({commit}, _data) {
      const url = state.HsApiUrl + 'score/delete'
      let response = await axios.post(url, _data, state.axiosHeader)
      // TDOO :: RELOAD ... 
     },
       
    setShowHighscore ({commit}, _data) {
      commit("setShowHighscore", _data)
    },
    setEventList ({commit}, _data) {
      commit("setEventList", _data)
    },

};
// ================== END ACTION  =========================

// ================== MUTATIONS =========================
const mutations = {

  setHSList(state, _data) {
    console.log('HighscoreX -> mutation -> setHSList', _data)

    let event = _data.event
   let temp = _data.highscoreList

 
   // TODO ... fieeees .,..
   // müsste so sein ... 
   //   if (event.HighscoreMode == 1) {
    // 
   if (_data.eventName == "allianz") {
    temp.sort((b,a) => a.points - b.points);
 } else {
    temp.sort((a,b) => a.points - b.points);
 }
    
    state.highscores[_data.eventName] = temp
    state.highscoresLimit[_data.eventName] = temp.slice(0, 10)
    console.log('HighscoreX -> mutation -> setHSList ->', state.highscoresLimit[_data.eventName])
  },

  setShowHighscore(state, _data) {
     console.log('HighscoreX -> mutation -> setShowHighscore ->', _data)
    state.showHighscore = _data;
  },

  setEventList(state, _data) {
    console.log('HighscoreX -> mutation -> setEventList ->', _data)
    state.eventList = _data;
  },

  setEvent(state, _data) {
    console.log('HighscoreX -> mutation -> setEvent ->', _data)
    state.namedEventList[_data.name] = _data;
 }

};

export default {
  state,
  getters,
  actions,
  mutations
};
 